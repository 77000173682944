import { useEffect } from "react";
import { useSession } from "../../../hooks/useSession/useSession";
import { useUserContext } from "../../../providers/UserProvider";

/**
 * Initiates the UserFlow session. Since the created date is one of the fields, a component was created so that
 * we can leverage the UserProvider and pull that information out.
 * @constructor
 */
export const UserFlow = () => {
    const { user } = useSession();
    const { profile } = useUserContext();

    useEffect(() => {
        const userflow = window.userflow;
        if (userflow && profile && user) {
            const signed_up_opti_id = profile.created.toISOString();
            const payload: { name?: string; email: string; signed_up_opti_id?: string } = {
                name: user.name, // using the okta name instead of the modifiable value
                email: profile.email
            };
            if (signed_up_opti_id) {
                payload["signed_up_opti_id"] = signed_up_opti_id;
            }

            userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
            userflow.identify(profile.email, payload);
        }
    }, [user, profile]);

    return <></>;
};

UserFlow.displayName = "UserFlow";
