import React from "react";
import { useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import styles from "./LayoutWrapper.module.scss";
import Layout from "./Layout";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { OptiCommonHeader } from "../../components/OptiCommonHeader/OptiCommonHeader";
import { useUserContext } from "../../../providers/UserProvider";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { ACCESS_TYPES, ATTRIBUTE_ROLES } from "../../../constants/constants";
import { getSecondaryNavigation } from "../../../lib/navigation-helpers";
import { UnauthorizedWithGlobalBar } from "../Unauthorized/UnauthorizedWithGlobalBar";
import { useOrganization } from "../../../hooks/useOrganization/useOrganization";

const LayoutWrapper = ({ children }: { children?: React.ReactNode }) => {
    const location = useLocation();
    const { pathname } = location;
    const pathParts = pathname.split("/");
    const path1 = pathParts[1];
    let layoutPath = path1;
    const { enabled: showRoles } = useFeatureFlag(Flags.SHOW_ROLES);
    const { enabled: showInvitations } = useFeatureFlag(Flags.SHOW_INVITATIONS);
    const { enabled: supportUsageTabEnabled } = useFeatureFlag(Flags.SHOW_SUPPORT_USAGE_TAB);
    const { enabled: showRolesSidebarNavigation } = useFeatureFlag(Flags.SHOW_ROLES_SIDEBAR_NAVIGATION);
    const { enabled: enableMasterDashboard } = useFeatureFlag(Flags.ENABLE_MASTER_DASHBOARD);
    const { enabled: enableUsersPage } = useFeatureFlag(Flags.ENABLE_SEPARATE_USERS_PAGE);
    const { enabled: enableSettingsPages } = useFeatureFlag(Flags.ENABLE_BILLING_NOTIFICATION_SETTINGS);
    const { enabled: enableInstanceManagerRoles } = useFeatureFlag(Flags.INSTANCE_USER_MANAGER_UPDATES);
    const { enabled: enableSSOSupportedLocalLogin } = useFeatureFlag(Flags.SSO_SUPPORTED_LOCAL_LOGIN);

    const { canUserDoAction, organizationId } = useUserContext();
    const { products } = useProducts({ organizationId });
    const { organization } = useOrganization({ id: organizationId });
    const { productId, instanceId, projectId } = useParams();

    const canDoUserActions = canUserDoAction({
        action: [ATTRIBUTE_ROLES.USERS.READ, ATTRIBUTE_ROLES.USERS.UPDATE]
    });
    const canDoGroupActions = canUserDoAction({
        action: [ATTRIBUTE_ROLES.GROUPS.READ, ATTRIBUTE_ROLES.GROUPS.CREATE, ATTRIBUTE_ROLES.GROUPS.UPDATE]
    });
    const canDoRoleActions = canUserDoAction({
        action: [ATTRIBUTE_ROLES.ROLES.READ, ATTRIBUTE_ROLES.ROLES.CREATE, ATTRIBUTE_ROLES.ROLES.UPDATE]
    });
    const canDoInvitationActions = canUserDoAction({
        action: [
            ATTRIBUTE_ROLES.INVITATIONS.READ,
            ATTRIBUTE_ROLES.INVITATIONS.CREATE,
            ATTRIBUTE_ROLES.INVITATIONS.UPDATE
        ]
    });
    const canDoProductActions = canUserDoAction({ action: ATTRIBUTE_ROLES.PRODUCTS.READ });

    const showSupportUsageTab = supportUsageTabEnabled && canUserDoAction({ action: ATTRIBUTE_ROLES.BILLING.READ });

    let productName = "",
        instanceNickname = "",
        isExpProduct = productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;

    // TODO: We shouldn't need to do this complex logic like this, instead the keys in the route tabs should correspond
    // to the actual routes and not some made up key
    if (layoutPath === "entitlements" && pathParts[2]) {
        layoutPath = "productInstance";
    } else if (layoutPath === "access" && productId) {
        if (!instanceId) {
            productName = products?.find((p) => p.id === productId)?.name ?? "";
            layoutPath = "products";
        } else {
            if (!projectId) {
                instanceNickname =
                    products?.flatMap((product) => product.instances).find((instance) => instance.id === instanceId)
                        ?.nickname || "";
                layoutPath = "instances";
            } else {
                layoutPath = "projectUsers";
            }
        }
    } else if (layoutPath === "settings") {
        layoutPath = pathParts[2] === "notifications" ? "settings/notifications" : "settings/authentication";
    }

    // Check if user has the required attributes to view the respective pages
    const canViewUsers = enableInstanceManagerRoles
        ? canUserDoAction({
              action: ATTRIBUTE_ROLES.USERS.READ,
              context: {
                  scopedAccess: ACCESS_TYPES.SUPER_ADMIN
              }
          })
        : canUserDoAction({
              action: ATTRIBUTE_ROLES.USERS.UPDATE,
              context: {
                  scopedAccess: ACCESS_TYPES.PRODUCT
              }
          });

    const canViewUsersAtInstanceLevel = canUserDoAction({
        action: ATTRIBUTE_ROLES.USERS.UPDATE,
        context: {
            InstanceId: instanceId
        }
    });

    const canViewInstanceUsersByProductAccess = canUserDoAction({
        action: ATTRIBUTE_ROLES.PRODUCTS.READ,
        context: {
            InstanceId: instanceId
        }
    });

    const hideProductsUserTab = !canViewUsers || enableUsersPage;
    const showLocalLoginSettings = enableSSOSupportedLocalLogin && organization?.ssoEnabled;

    const routeTabs: any = {
        "account-management": {
            headerText: "Settings",
            tabs: [
                { title: "SSO", route: "/account-management/sso" },
                { title: "Domains / Dynamic Provisioning", route: "/account-management/domains" },
                showLocalLoginSettings
                    ? { title: "Local Login", route: "/account-management/local-login" }
                    : { title: "MFA", route: "/account-management/mfa" }
            ]
        },
        "settings/authentication": {
            headerText: "Settings",
            tabs: [
                { title: "SSO", route: "/settings/authentication/sso" },
                { title: "Domains / Dynamic Provisioning", route: "/settings/authentication/domains" },
                showLocalLoginSettings
                    ? { title: "Local Login", route: "/settings/authentication/local-login" }
                    : { title: "MFA", route: "/settings/authentication/mfa" }
            ]
        },
        "settings/notifications": {
            headerText: "Notifications",
            tabs: [{ title: "Billing Notifications", route: "/settings/notifications/billing" }]
        },
        access: {
            headerText: "Product Access",
            pageDescription:
                "Product Access empowers you to oversee and administer users on a product-specific level. With this feature, you can assign roles directly to users for each product you're associated with, ensuring precise access levels. Additionally, you have the capability to extend invitations to users, granting them the appropriate level of access required for their involvement.",
            tabs: [
                { title: "Products", route: "/access/products" },
                !hideProductsUserTab && { title: "Users", route: "/access/users" }
            ].filter(Boolean)
        },
        products: {
            headerText: productName,
            tabs: [
                { title: "Instances", route: `/access/products/${productId}/instances` },
                canViewUsers && { title: "Users", route: `/access/products/${productId}/users` }
            ].filter(Boolean)
        },
        instances: {
            headerText: instanceNickname,
            tabs: [
                (canViewUsersAtInstanceLevel || canViewInstanceUsersByProductAccess) && {
                    title: "Users",
                    route: `/access/products/${productId}/instances/${instanceId}/users`
                },
                canDoProductActions
                    ? { title: "Details", route: `/access/products/${productId}/instances/${instanceId}/details` }
                    : undefined
            ].filter(Boolean) //filters out null/undefined items
        },
        projectUsers: {
            headerText: ""
        },
        entitlements: {
            headerText: "Products & Instances"
        },
        productInstance: {
            headerText: ""
        },
        usage: {
            headerText: "Usage & Billing"
        },
        support: {
            headerText: "Support",
            tabs: [
                { title: "Access", route: "/support/access" },
                { title: "Account Activation", route: "/support/account-activation" },
                { title: "User Home Organization", route: "/support/user-migration" },
                enableMasterDashboard && { title: "Master Dashboard", route: "/support/master-dashboard" },
                showSupportUsageTab && { title: "Usage & Billing", route: "/support/usage" }
            ].filter(Boolean) // remove any `false` items
        },
        roles: {
            headerText: "Roles"
        },
        groups: {
            headerText: "Group Access",
            pageDescription:
                "Group Access facilitates the allocation of product permissions based on groups, streamlining the process of granting access to multiple individuals simultaneously. This functionality empowers you to implement intricate combinations of permissions across various user groups, ensuring efficient management of access rights across your organization."
        },
        default: {
            headerText: enableUsersPage ? "Users" : "Group Access",
            pageDescription:
                !enableUsersPage &&
                "Group Access facilitates the allocation of product permissions based on groups, streamlining the process of granting access to multiple individuals simultaneously. This functionality empowers you to implement intricate combinations of permissions across various user groups, ensuring efficient management of access rights across your organization.",
            tabs: [
                canDoGroupActions && !enableUsersPage && { title: "Groups", route: "/user-management/user-groups" },
                canDoUserActions && { title: "Users", route: "/user-management/users" },
                canDoRoleActions &&
                    showRoles &&
                    !showRolesSidebarNavigation &&
                    !enableUsersPage && { title: "Roles", route: "/user-management/roles" },
                canDoInvitationActions &&
                    showInvitations && { title: "Invitations", route: "/user-management/invitations" }
            ].filter(Boolean) // remove any `false` items
        }
    };

    if (isExpProduct) {
        routeTabs.instances.tabs.unshift({
            title: "Projects",
            route: `/access/products/${productId}/instances/${instanceId}/projects`
        });
    }

    const routeDetails = routeTabs[layoutPath] || routeTabs["default"];
    const Submenu = getSecondaryNavigation({ path: pathname });

    const settings404 = !enableSettingsPages && path1 === "settings";
    const localLogin404 = !showLocalLoginSettings && pathname.includes("local-login");
    const mfa404 = showLocalLoginSettings && pathname.includes("mfa");
    const showNotFound = settings404 || localLogin404 || mfa404;

    if (showNotFound) {
        return (
            <>
                <OptiCommonHeader />
                <div className={classNames("flex", styles["layout-wrapper"])}>
                    <div className="flex--1 not-found-container">
                        <opti-error-404></opti-error-404>
                    </div>
                </div>
            </>
        );
    }

    const canViewGroups = enableInstanceManagerRoles
        ? canUserDoAction({
              action: ATTRIBUTE_ROLES.USERS.READ,
              context: {
                  scopedAccess: ACCESS_TYPES.SUPER_ADMIN
              }
          })
        : canUserDoAction({
              action: ATTRIBUTE_ROLES.GROUPS.CREATE,
              context: {
                  scopedAccess: ACCESS_TYPES.PRODUCT
              }
          });

    const canViewRoles = enableInstanceManagerRoles
        ? canUserDoAction({
              action: ATTRIBUTE_ROLES.ROLES.READ,
              context: {
                  scopedAccess: ACCESS_TYPES.SUPER_ADMIN
              }
          }) ||
          canUserDoAction({
              action: ATTRIBUTE_ROLES.ROLES.READ,
              context: {
                  scopedAccess: ACCESS_TYPES.PRODUCT
              }
          }) ||
          canUserDoAction({
              action: ATTRIBUTE_ROLES.ROLES.READ,
              context: {
                  scopedAccess: ACCESS_TYPES.INSTANCE
              }
          })
        : canUserDoAction({
              action: ATTRIBUTE_ROLES.ROLES.CREATE
          });

    const userRouteAndAttributeRequirements = [
        { pattern: RegExp("/user-management/users"), canView: canViewUsers },
        { pattern: RegExp("/access/users"), canView: canViewUsers },
        { pattern: RegExp("/access/products/[^/]+/users$"), canView: canViewUsersAtInstanceLevel }
    ];

    const userRouteRequirement = userRouteAndAttributeRequirements.find((i) => {
        return pathname.match(i.pattern);
    });
    const noAccessUserVisitor = userRouteRequirement && !userRouteRequirement.canView;

    const userGroupRoutes = ["/user-management/user-groups", "/user-management/user-groups/", "/groups", "/groups/"];
    const roleRoutes = ["/user-management/roles", "/user-management/roles/", "/roles", "/roles/"];

    const showNoAccess =
        noAccessUserVisitor ||
        (!canViewGroups && userGroupRoutes.indexOf(pathname) > -1) ||
        (!canViewRoles && roleRoutes.indexOf(pathname) > -1);

    if (showNoAccess) {
        return <UnauthorizedWithGlobalBar />;
    }

    return (
        <>
            <OptiCommonHeader />
            <div className={classNames("flex", styles["layout-wrapper"])}>
                <div className={styles["layout-wrapper__navigation"]}>
                    <NavigationBar />
                </div>
                {!!Submenu && (
                    <div className={styles["layout-wrapper__navigation"]}>
                        <Submenu />
                    </div>
                )}
                <div className="flex--1">
                    <Layout
                        headerText={routeDetails.headerText}
                        tabs={routeDetails.tabs}
                        pageDescription={routeDetails.pageDescription}
                    >
                        {children}
                    </Layout>
                </div>
            </div>
        </>
    );
};

export default LayoutWrapper;
