/* eslint-disable react/style-prop-object */
import { Attention, Button, Spinner, Typography } from "@optimizely/axiom";
import React, { useState } from "react";
import { isEmail } from "../../../lib/validators";
import { useForm } from "react-hook-form";
import { CustomerSelectionDropdown } from "../CustomerSelectionDropdown/CustomerSelectionDropdown";
import { Customer } from "../../../domain/Customer";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { useServicesContext } from "../../../providers/ServicesProvider";
import { User } from "../../../domain/User";

interface IAccountSupportFormValues {
    organizationName?: string;
    email: string;
    organizationId: string;
}
export const OrganizationMigrationForm = () => {
    const { userService } = useServicesContext();
    const {
        register,
        watch,
        getValues,
        reset,
        formState: { errors },
        setValue
    } = useForm<IAccountSupportFormValues>({
        mode: "onChange"
    });
    watch(["email", "organizationId"]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updatedUser, setUpdatedUser] = useState<null | User>(null);
    const { email, organizationId, organizationName } = getValues();
    const disableSubmit = loading || ![email, organizationId].every((v) => !!v);

    const setOrganizationId = ({ customer }: { customer: Customer }) => {
        const { id, name } = customer;
        setValue("organizationName", name);
        setValue("organizationId", id);
    };

    const handleConfirmation = () => {
        setLoading(true);
        setShowConfirmation(false);
        userService
            .migrateUser({ email, organizationId })
            .then((res) => {
                setUpdatedUser(res);
            })
            .catch((error) => {
                console.error({ error });
                if (Array.isArray(error) && error?.length) {
                    setError(error[0]?.message);
                } else if (error.message) {
                    setError(error.message);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSubmit = () => {
        setError(null);
        setShowConfirmation(true);
    };

    const resetForm = () => {
        reset();
        setUpdatedUser(null);
    };

    return (
        <div className="width--6-12">
            <div className="push-double--top push-double--bottom">
                <Typography type="header1" className="push-triple--top">
                    User Home Organization
                </Typography>
            </div>
            {!!error && (
                <Attention type="bad-news" className="push--bottom">
                    {error}
                </Attention>
            )}
            {!!updatedUser && (
                <div className="flex flex--column">
                    <Attention type="good-news" className="push-double--bottom">
                        User's home organization successfully updated
                    </Attention>
                    <Typography type="subhead">
                        {updatedUser?.firstName} {updatedUser?.lastName}
                    </Typography>
                    <Typography type="body" className="push-double--bottom">
                        {email}
                    </Typography>
                    <Typography type="subhead">New Home Organization:</Typography>
                    <Typography type="body">{organizationName}</Typography>

                    <Button style="outline" className="push-double--top max-width--200" onClick={resetForm}>
                        Update Another User
                    </Button>
                </div>
            )}
            {!updatedUser && (
                <form className="flex flex--column user-form">
                    <Typography type="body" tag="p">
                        Use this form to move a user from one home organization to another.
                    </Typography>
                    <div className="oui-dropdown-group push-double--ends min-width--250 width--7-12">
                        <input
                            placeholder="User email..."
                            aria-describedby="user-email-error"
                            className="oui-text-input"
                            id="user-email"
                            disabled={loading}
                            type="email"
                            {...register("email", {
                                validate: { isEmail },
                                required: { value: true, message: "Email is required" }
                            })}
                        />
                        {errors.email && (
                            <div className="oui-form-bad-news">
                                <span className="oui-form-note">{errors.email.message}</span>
                            </div>
                        )}
                    </div>
                    <CustomerSelectionDropdown
                        disabled={loading}
                        onSelect={setOrganizationId}
                        className="min-width--250 width--7-12"
                    />

                    <Button
                        className="push-quad--top min-width--250 max-width--300"
                        style="highlight"
                        isDisabled={disableSubmit}
                        onClick={handleSubmit}
                    >
                        {loading ? (
                            <>
                                <Spinner size="small" className="push--right" />
                                Loading...
                            </>
                        ) : (
                            "Update User's Home Organization"
                        )}
                    </Button>
                </form>
            )}
            {showConfirmation && (
                <ConfirmationDialog
                    title="Update User's Home Organization"
                    callToActionText="Update"
                    style="highlight"
                    onCancel={() => setShowConfirmation(false)}
                    onConfirmation={handleConfirmation}
                >
                    Changing home organization may change the status of the user in OptiId/Okta.
                    <ul style={{ listStyle: "unset" }}>
                        <li>
                            <strong style={{ fontWeight: "700" }}>SSO to Local Login</strong>: Users will receive an
                            email prompting them to set a new password.
                        </li>
                        <li>
                            <strong style={{ fontWeight: "700" }}>Local Login to SSO</strong>: Users will no longer be
                            able to use their current local password and must log in with their organization’s SSO
                            credentials.
                        </li>
                        <li>
                            <strong style={{ fontWeight: "700" }}>SSO with Local Login to an SSO Organization</strong>:
                            Users with local login enabled will retain this option if the new organization has local
                            login enabled; otherwise, they will be switched to SSO login.
                        </li>
                        <li>
                            <strong style={{ fontWeight: "700" }}>SSO with Local Login to a local Organization</strong>:
                            The local login option on the user will be turned off, but the user can continue to use the
                            their local pwd to login to OptiId using the local organization they were moved to.
                        </li>
                    </ul>
                </ConfirmationDialog>
            )}
        </div>
    );
};

OrganizationMigrationForm.displayName = "AccountSupport";
