import useSWR from "swr";
import { useServicesContext } from "../../providers/ServicesProvider";
import { ILoginSettings, LoginSettings } from "../../domain/LoginSettings";

export const useLocalLoginSettings = ({ organizationId }: { organizationId?: string }) => {
    const { organizationSettingsService } = useServicesContext();

    const {
        data: localLoginSettings,
        error,
        mutate
    } = useSWR<LoginSettings, Error>(
        organizationId ? `/api/organizations/${organizationId}/settings/locallogin` : null,
        () => {
            if (!organizationId) return {} as LoginSettings;
            return organizationSettingsService!.getLocalLoginSettings({ organizationId });
        }
    );

    const updateLocalLoginSettings = async ({ settings }: { settings: ILoginSettings }) => {
        if (organizationId) {
            const result = await organizationSettingsService!.updateLocalLoginSettings({ organizationId, settings });
            if (result) await mutate(result);
        }
    };

    return {
        localLoginSettings,
        loading: !error && !localLoginSettings,
        error,
        updateLocalLoginSettings
    };
};
