import { Attention, Typography } from "@optimizely/axiom";

export const SupportHipaaWarning = ({ className }: { className?: string }) => {
    return (
        <Attention type="warning" className={className}>
            <Typography type="subhead">Accessing HIPAA Accounts</Typography>
            <br />
            <br />
            <Typography type="body" tag="p">
                For HIPAA-compliant Optimizely accounts, support tool access isn’t permitted. To access a HIPAA account:
            </Typography>
            <br />
            <Typography type="body" tag="p">
                <span className="weight--bold">1. Request invitation:</span> Ask the customer to invite you to their
                organization.
                <br />
                <span className="weight--bold">2. Access via invitation:</span> Once invited, support them within their
                organization.
            </Typography>
            <br />
            <Typography type="body" tag="p">
                This process ensures HIPAA compliance. If you can't locate the account and it's not HIPAA, try the
                parent company name.
            </Typography>
        </Attention>
    );
};

SupportHipaaWarning.displayName = "SupportHipaaWarning";
