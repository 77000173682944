import React, { ReactElement, useEffect, useRef, useState } from "react";

export const InfiniteScrollList = ({
    children,
    loadNext,
    isLoadingMore,
    loadingItem
}: {
    children: React.ReactNode;
    loadNext: () => void;
    isLoadingMore?: boolean;
    loadingItem: ReactElement;
}): React.ReactElement => {
    const [lastElement, setLastElement] = useState<HTMLElement | null>();

    const observer = useRef(
        new IntersectionObserver(async (entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                loadNext();
            }
        })
    );

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    return (
        <>
            {children}
            {/*Cloning the loadingItem passed into the component and hiding it from the UI to set the lastElement ensures*/}
            {/*the child is of the proper type so that React will not produce errors when validating the DOM*/}
            {React.cloneElement(loadingItem, {
                ref: setLastElement,
                style: { visibility: "hidden", height: "0px", maxHeight: "0px" },
                key: "last-scroll-child"
            })}
            {isLoadingMore && loadingItem}
        </>
    );
};
