import { Spinner, Switch, Typography, TypographyTypes } from "@optimizely/axiom";

import styles from "./SettingsToggle.module.scss";

interface SettingsToggleProps {
    disableToggle?: boolean;
    elementId: string;
    handleToggle: (elementId: string) => void;
    isEnabled?: boolean;
    label: string;
    loading?: boolean;
    titleType: TypographyTypes;
}

export const SettingsToggle = ({
    disableToggle = false,
    elementId,
    handleToggle,
    isEnabled = false,
    label,
    loading = false,
    titleType
}: SettingsToggleProps) => {
    return (
        <Typography type={titleType} className={styles["settings-toggle"]}>
            {label}
            <Switch
                elementId={elementId}
                isDisabled={disableToggle}
                checked={isEnabled}
                onClick={() => handleToggle(elementId)}
            />
            {loading && <Spinner />}
        </Typography>
    );
};

SettingsToggle.displayName = "SettingsToggle";
