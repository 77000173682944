import { useServicesContext } from "../../providers/ServicesProvider";
import { ProductAttribute } from "../../domain/ProductAttribute";
import useSWR from "swr";

export const useAttribute = ({ attributeId }: { attributeId?: string }) => {
    const { attributeService } = useServicesContext();

    const {
        data: attribute,
        error,
        isValidating
    } = useSWR<ProductAttribute>(attributeId ? `/api/attribute?attributeId=${attributeId}` : null, () => {
        return attributeService!.getAttribute(attributeId!);
    });

    return {
        attribute,
        isLoading: !attribute && !error && !isValidating,
        error
    };
};
