export interface ILoginSettings {
    localLoginEnabled: boolean;
    mfaEnabled: boolean;
}

export class LoginSettings implements ILoginSettings {
    constructor(settings: any) {
        this.localLoginEnabled = settings.Enabled;
        this.mfaEnabled = settings.MfaEnabled;
    }

    localLoginEnabled: boolean;
    mfaEnabled: boolean;
}
