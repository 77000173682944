import { useMemo, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useUserContext } from "../../../providers/UserProvider";
import { useProducts } from "../../../hooks/useProducts/useProducts";
import { IOrganizationProductInstance, OrganizationProduct } from "../../../domain/OrganizationProduct";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { Icon, Typography } from "@optimizely/axiom";
import { ATTRIBUTE_ROLES } from "../../../constants/constants";
import { useAttribute } from "../../../hooks/useAttribute/useAttribute";

export const Breadcrumbs = () => {
    const { enabled: breadcrumbsEnabled } = useFeatureFlag(Flags.ENABLE_BREADCRUMBS);
    const location = useLocation();
    const pathNames = location.pathname.split("/").filter(Boolean);

    const displayedPathNames = pathNames.slice(1).filter((path) => path !== "instances" && path !== "projects");
    const displayBreadcrumbs = breadcrumbsEnabled && pathNames && pathNames[0]?.toLowerCase() === "access";

    const { productId, instanceId, projectId } = useParams();
    const { organizationId = "", canUserDoAction } = useUserContext();
    const { products = [] } = useProducts({ organizationId });
    const { attribute: project } = useAttribute({ attributeId: projectId });
    const [product, setProduct] = useState<OrganizationProduct | undefined>(undefined);
    const [instance, setInstance] = useState<IOrganizationProductInstance | undefined>(undefined);
    const canReadProducts = canUserDoAction({ action: ATTRIBUTE_ROLES.PRODUCTS.READ });

    useEffect(() => {
        setProduct(products.find((x) => x.id === productId));
        setInstance(
            (products as OrganizationProduct[])
                .find((x) => x.id === productId)
                ?.instances?.find((i) => i.id === instanceId)
        );
    }, [instanceId, productId, products]);

    const labelMapping: { [key: string]: string } = useMemo(() => {
        return {
            ...(productId ? { [productId]: product?.name ?? "" } : {}),
            ...(instanceId ? { [instanceId]: (instance?.nickname || instance?.name) ?? "" } : {}),
            ...(projectId ? { [projectId]: project?.name ?? "" } : {}),
            products: "Products"
        };
    }, [productId, instanceId, projectId, product, instance, project?.name]);

    if (!displayBreadcrumbs) return null;

    return (
        <div className="breadcrumbs">
            <Typography type="body">
                {displayedPathNames.map((value, index) => {
                    const isFirst = index === 0;
                    const isLast = index === displayedPathNames.length - 1;
                    // slice path off at a page component route
                    const pathSlice = 2 * index + 2;
                    const redirectPage = pathNames[pathSlice - 1];

                    const label = labelMapping[value] || value.replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());

                    const redirectPath = `/${pathNames.slice(0, pathSlice).join("/")}`;

                    const productsBreadcrumbButNoPermissions = value === productId && !canReadProducts;
                    const instancesCrumbWithNoProductReadPermissions = value === instanceId && !canReadProducts;
                    const redirectToUsersOrDetailsPage = redirectPage === "users" || redirectPage === "details";

                    const disableBreadcrumbNavigation =
                        isLast ||
                        productsBreadcrumbButNoPermissions ||
                        instancesCrumbWithNoProductReadPermissions ||
                        redirectToUsersOrDetailsPage;

                    return (
                        <span key={index}>
                            {!isFirst && (
                                <span>
                                    <Icon name="angle-right" size="small" />
                                </span>
                            )}
                            {disableBreadcrumbNavigation ? (
                                <span>{label}</span>
                            ) : (
                                <Link key={redirectPath} to={redirectPath}>
                                    {label}
                                </Link>
                            )}
                        </span>
                    );
                })}
            </Typography>
        </div>
    );
};
