import { IIdentityRepository } from "../../repositories/IdentityRepository";
import { LoginSettings } from "../../domain/LoginSettings";

export interface IOrganizationSettingsService {
    getLocalLoginSettings({ organizationId }: { organizationId: string }): Promise<LoginSettings>;
    updateLocalLoginSettings({
        organizationId,
        settings
    }: {
        organizationId: string;
        settings: LoginSettings;
    }): Promise<LoginSettings>;
}

export class OrganizationSettingsService implements IOrganizationSettingsService {
    identityRepository: IIdentityRepository;

    constructor(identityRepository: IIdentityRepository) {
        this.identityRepository = identityRepository;
    }

    public async getLocalLoginSettings({ organizationId }: { organizationId: string }): Promise<LoginSettings> {
        const result = await this.identityRepository.getLocalLoginSettings({ organizationId });
        return new LoginSettings(result.Settings);
    }

    public async updateLocalLoginSettings({
        organizationId,
        settings
    }: {
        organizationId: string;
        settings: LoginSettings;
    }): Promise<LoginSettings> {
        const adaptedSettings = {
            enabled: settings?.localLoginEnabled,
            mfaEnabled: settings?.mfaEnabled
        };

        const result = await this.identityRepository.updateLocalLoginSettings({
            organizationId,
            settings: adaptedSettings
        });
        return new LoginSettings(result);
    }
}
