import React from "react";
import { Skeleton } from "../Skeleton/Skeleton";
import { useAttribute } from "../../../hooks/useAttribute/useAttribute";

export const ProjectDisplayName = ({ attributeId }: { attributeId: string }) => {
    const { attribute: project, isLoading } = useAttribute({ attributeId });

    return isLoading ? (
        <Skeleton
            className="display--inline-block push--sides soft--top"
            key={attributeId}
            height="17px"
            width="100px"
        />
    ) : (
        <>{project?.name}</>
    );
};

ProjectDisplayName.displayName = "ProjectDisplayName";
