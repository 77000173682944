import { Attention, LayoutGridCell, Typography } from "@optimizely/axiom";
import React, { useEffect, useState } from "react";
import { useUserContext } from "../../../providers/UserProvider";
import { useOrganizationIdp } from "../../../hooks/useOrganizationIdp/useOrganizationIdp";
import { useOrganization } from "../../../hooks/useOrganization/useOrganization";
import { SettingsToggle } from "../../components/Toggles/SettingsToggle";

export const MFA = () => {
    const { organizationId } = useUserContext();
    const { error: idpError, isLoadingIdp, toggleMFA } = useOrganizationIdp(organizationId);
    const { organization } = useOrganization({ id: organizationId });
    const [mfaOn, setMFAOn] = useState(organization?.mfaEnabled || false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { ssoEnabled, mfaEnabled } = organization || {};
    useEffect(() => {
        setMFAOn(mfaEnabled || false);
    }, [mfaEnabled]);

    const switchMFA = () => {
        setLoading(true);
        setError("");
        const newValue = !mfaOn;
        toggleMFA({ enabled: newValue })
            .then(() => {
                setMFAOn(newValue);
            })
            .catch((error) => {
                setError(error?.message || `${error}`);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="width--7-12">
            <div className="push-double--top push-double--bottom">
                <SettingsToggle
                    elementId="mfa-switch"
                    label="Multi-Factor Authentication (MFA)"
                    titleType="header1"
                    loading={loading}
                    isEnabled={mfaOn}
                    handleToggle={switchMFA}
                    disableToggle={!!idpError || isLoadingIdp || loading || ssoEnabled}
                />
            </div>
            <LayoutGridCell xlarge={6} large={6} medium={8} small={4}>
                {ssoEnabled && (
                    <Attention type="warning">
                        MFA is disabled because your organization has setup an SSO connection. MFA may be available
                        through your upstream IDP. Please contact them for more information.
                    </Attention>
                )}
                {!!(error || idpError) && (
                    <Attention type="bad-news">An error occurred. Please try again later.</Attention>
                )}
                <div className="push-double--top push-double--bottom">
                    <Typography tag="p" type="caption">
                        MFA is currently only available for organizations set up with local login.
                    </Typography>
                </div>

                <div className="push-double--top push-double--bottom">
                    <Typography tag="p" type="caption">
                        Selecting to enable Multi-Factor Authentication (MFA) will mean users in your organization will
                        be prompted to setup two-factor authentication on their device (during their first login after
                        enabling MFA) and provide a verification code during login to successfully login to OptiId.
                        <br />
                        <br />
                        <Typography type="subhead" tag="span">
                            Note:
                        </Typography>{" "}
                        There may be a slight delay between updating MFA and seeing the change in the login process.
                    </Typography>
                </div>
            </LayoutGridCell>
        </div>
    );
};

MFA.displayName = "MFAPage";
