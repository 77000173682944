import { Typography } from "@optimizely/axiom";
import { SettingsToggle } from "./SettingsToggle";

import styles from "./LocalLoginSettingsToggles.module.scss";

interface LocalLoginSettingsTogglesProps {
    handleToggle: (elementId: string) => void;
    localLoginEnabled: boolean;
    mfaEnabled: boolean;
}

export const LocalLoginSettingsToggles = ({
    handleToggle,
    localLoginEnabled,
    mfaEnabled
}: LocalLoginSettingsTogglesProps) => {
    return (
        <div>
            <section className={styles["setting"]}>
                <SettingsToggle
                    label="Enable Local Login"
                    elementId="local-login-toggle"
                    isEnabled={localLoginEnabled}
                    handleToggle={handleToggle}
                    titleType="header2"
                />
                <Typography type="body" className={styles["setting__description"]}>
                    Turning this toggle "On" will allow Super Admins to enable selected users to log into Opti ID with a
                    password instead of the organization's SSO. This option provides local login access for partners and
                    other external collaborators within your organization.
                </Typography>
            </section>
            <section className={styles["setting"]}>
                <SettingsToggle
                    label="Multi-Factor Authentication (MFA)"
                    elementId="mfa-toggle"
                    disableToggle={!localLoginEnabled}
                    isEnabled={mfaEnabled}
                    handleToggle={handleToggle}
                    titleType="header2"
                />
                <Typography type="body" className={styles["setting__description"]}>
                    MFA is currently only available for and will only affect local login users.
                    <div className="push-double--top">
                        Selecting to enable Multi-Factor Authentication (MFA) will mean local-login users in your
                        organization will be prompted to setup two-factor authentication on their device (during their
                        first login after enabling MFA) and provide a verification code during login to successfully
                        login to OptiId.
                    </div>
                    <div className="push-double--top">
                        <span className="weight--bold">Note:</span> There may be a slight delay between updating MFA and
                        seeing the change in the login process.
                    </div>
                </Typography>
            </section>
        </div>
    );
};

LocalLoginSettingsToggles.displayName = "LocalLoginSettingsToggles";
